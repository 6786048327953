var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": _vm.$t("kayttajatSelaa.quickSearch"),
          "vuex-module-name": _vm.vuexModuleName,
          "search-panel": false,
        },
      }),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "käyttäjää",
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.kayttajat,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: { clickable: true, data: item, fade: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.setSelectedItem({ item: item })
                      },
                    },
                  },
                  [
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.username) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.email) + " "),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      _vm._l(item.groups, function (ryhma) {
                        return _c("div", { key: ryhma }, [
                          _vm._v(" " + _vm._s(ryhma) + " "),
                        ])
                      }),
                      0
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.is_active) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.is_superuser) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.is_staff) + " "),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-navigation-drawer",
        {
          ref: "offCanvas",
          attrs: { width: "450", app: "", right: "", temporary: "" },
          model: {
            value: _vm.showOffCanvas,
            callback: function ($$v) {
              _vm.showOffCanvas = $$v
            },
            expression: "showOffCanvas",
          },
        },
        [
          _vm.loadingOffCanvas
            ? _c("Spinner")
            : _c("OffCanvasKayttaja", {
                attrs: { kayttaja: _vm.selectedItem.item },
                on: { "close-button-click": _vm.setShowOffCanvas },
              }),
        ],
        1
      ),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }