<template>
  <div>
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      :pikahaun-placeholder-teksti="$t('kayttajatSelaa.quickSearch')"
      :vuex-module-name="vuexModuleName"
      :search-panel="false"
    />

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="käyttäjää"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="kayttajat"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :fade="loading"
          @click="setSelectedItem({ item })"
        >
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap">
            {{ item.username }}
          </td>
          <td class="text-no-wrap">
            {{ item.email }}
          </td>
          <td class="text-no-wrap">
            <div
              v-for="ryhma of item.groups"
              :key="ryhma"
            >
              {{ ryhma }}
            </div>
          </td>
          <td class="text-no-wrap">
            {{ item.is_active }}
          </td>
          <td class="text-no-wrap">
            {{ item.is_superuser }}
          </td>
          <td class="text-no-wrap">
            {{ item.is_staff }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasKayttaja
        v-else
        :kayttaja="selectedItem.item"
        @close-button-click="setShowOffCanvas"
      />
    </v-navigation-drawer>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>

import {
  Pagination,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
  OffCanvasKayttaja,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersKayttajat } from '@/utils/tableHeaders'

export default {
  name: 'KayttajatSelaa',
  components: {
    Pagination,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
    OffCanvasKayttaja,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      tableHeaders: TableHeadersKayttajat,
    }
  },
  computed: {
    kayttajat () {
      return this.items.map(k => ({
        ...k,
        is_active: k.is_active ? this.$t('kylla') : this.$t('ei'),
        is_superuser: k.is_superuser ? this.$t('kylla') : this.$t('ei'),
        is_staff: k.is_staff ? this.$t('kylla') : this.$t('ei'),
      }))
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
